<template lang="pug">
b-field.k-field-is-b-field(
  :style="containerStyle"
  :class="containerClass"
  :label="label"
  :type="type"
  :message="message"
  :grouped="grouped"
  :horizontal="horizontal"
  :addons="addons"
  :label-position="labelPosition"
)
  template(#label)
    slot(name="label")
  slot
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  label: { type: String },
  type: { type: String },
  message: { required: false },
  grouped: { type: Boolean },
  horizontal: { type: Boolean },
  addons: { type: Boolean },
  required: { type: Boolean },
  labelPosition: { type: String },

  // customize
  skipLabel: { type: Boolean },
  isSpaceBetween: { type: Boolean },
  labelFlexGrow: { type: Number },
  bodyFlexGrow: { type: Number }
})

const containerClass = computed(() => {
  return {
    required: props.required,
    'is-space-between': props.isSpaceBetween,
    'skip-label': props.skipLabel
  }
})

const containerStyle = computed(() => {
  return [
    props.labelFlexGrow ? `--label-flex-grow: ${props.labelFlexGrow}` : null,
    props.bodyFlexGrow ? `--body-flex-grow: ${props.bodyFlexGrow}` : null
  ]
    .filter(Boolean)
    .join(';')
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/size.sass'

.k-field-is-b-field
  position: relative
  &.required
    .field-label label
      position: relative
      &:after
        right: 0
        top: 50%
        transform: translate(100%, -50%)
        position: absolute
        content: '*'
        color: color.$brick-red
        font-size: font.$size-5

  &.skip-label
    .field-label
      display: none

  &.is-space-between
    .field-label
      display: flex
      justify-content: flex-start
      flex-grow: 2
    .field-body .field
      display: flex
      justify-content: flex-end

  &.is-horizontal
    .field-label
      flex-grow: var(--label-flex-grow, 1)

    .field-body
      flex-grow: var(--body-flex-grow, 5)
</style>
