import * as types from '../mutation-types'

export const createPreferredCategoryShipWithProducts = (
  { dispatch, commit },
  { model, preferredCategoryIds }
) => {
  commit(types.API_REQUEST_START, 'createPreferredCategoryShipWithProducts')

  return new Promise((resolve, reject) => {
    model
      .createPreferredCategoryShipWithProducts({
        preferredCategoryIds: preferredCategoryIds
      })
      .then((response) => {
        dispatch(
          'promoterShareProductShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(
          types.API_REQUEST_SUCCESS,
          'createPreferredCategoryShipWithProducts'
        )

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createPreferredCategoryShipWithProducts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              preferredCategoryIds
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
