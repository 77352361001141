import * as types from '../mutation-types'

export const fetchCampaigns = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchCampaigns')

  return new Promise((resolve, reject) => {
    model
      .fetchCampaigns(options)
      .then((response) => {
        dispatch(
          'promoterCampaigns/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('partners/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'promoterCampaignImages/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignPeriods/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchCampaigns')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchCampaigns,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const getCampaign = ({ dispatch, commit }, { model, options = {} }) => {
  commit(types.API_REQUEST_START, 'getCampaign')

  return new Promise((resolve, reject) => {
    model
      .getCampaign(options)
      .then((response) => {
        dispatch(
          'promoterCampaigns/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'getCampaign')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getCampaign,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
