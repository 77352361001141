import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'product_videos',
  attributes: [
    'id',
    'video',
    'original_filename',
    'is_primary',
    'position',
    'is_transferred',
    'product_id',
    'variant_id',
    'created_at',
    'updated_at'
  ],
  editableAttributes: ['variant_id']
}

export default class ProductVideo extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
