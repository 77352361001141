import * as types from '../mutation-types'

export const updateBankAccountInfoCompany = (
  { dispatch, commit },
  { model, bankAccountInfo }
) => {
  commit(types.API_REQUEST_START, 'updateBankAccountInfoCompany')

  return new Promise((resolve, reject) => {
    model
      .updateBankAccountInfoCompany(bankAccountInfo)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateBankAccountInfoCompany,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              bankAccountInfo
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const updateBankAccountInfoResidenceAddress = (
  { dispatch, commit },
  { model, bankAccountInfo }
) => {
  commit(types.API_REQUEST_START, 'updateBankAccountInfoResidenceAddress')

  return new Promise((resolve, reject) => {
    model
      .updateBankAccountInfoResidenceAddress(bankAccountInfo)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateBankAccountInfoResidenceAddress,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              bankAccountInfo
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const updateBankAccountInfoContactAddress = (
  { dispatch, commit },
  { model, bankAccountInfo }
) => {
  commit(types.API_REQUEST_START, 'updateBankAccountInfoContactAddress')

  return new Promise((resolve, reject) => {
    model
      .updateBankAccountInfoContactAddress(bankAccountInfo)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateBankAccountInfoContactAddress,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              bankAccountInfo
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const updateBankAccountInfoTransferAccount = (
  { dispatch, commit },
  model
) => {
  commit(types.API_REQUEST_START, 'updateBankAccountInfoTransferAccount')

  return new Promise((resolve, reject) => {
    model
      .updateBankAccountInfoTransferAccount()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateBankAccountInfoTransferAccount,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const updateBankAccountInfoIdPhoto = (
  { dispatch, commit },
  { model, formData }
) => {
  commit(types.API_REQUEST_START, 'updateBankAccountInfoIdPhoto')

  return new Promise((resolve, reject) => {
    model
      .updateBankAccountInfoIdPhoto(formData)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateBankAccountInfoIdPhoto,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateBankAccountInfoESignature = (
  { dispatch, commit },
  { model, formData }
) => {
  commit(types.API_REQUEST_START, 'updateBankAccountInfoESignature')

  return new Promise((resolve, reject) => {
    model
      .updateBankAccountInfoESignature(formData)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateBankAccountInfoESignature,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

// update bandAccountInfo
export const updateBankAccountInfo = (
  { dispatch, commit },
  { model, promoterId }
) => {
  commit(types.API_REQUEST_START, 'updateBankAccountInfo')

  return new Promise((resolve, reject) => {
    model
      .updateBankAccountInfo(promoterId)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateBankAccountInfo,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              promoterId
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const updateBankAccountInfoV2 = (
  { dispatch, commit },
  { model, options }
) => {
  commit(types.API_REQUEST_START, 'updateBankAccountInfoV2')

  return new Promise((resolve, reject) => {
    model
      .updateBankAccountInfoV2(options)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateBankAccountInfoV2,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const updateBankAccountInfoAccountPhoto = (
  { dispatch, commit },
  { model, formData }
) => {
  commit(types.API_REQUEST_START, 'updateBankAccountInfoAccountPhoto')

  return new Promise((resolve, reject) => {
    model
      .updateBankAccountInfoAccountPhoto(formData)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateBankAccountInfoAccountPhoto,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
