import PromoterMember from '../../../resource_models/promoter_member'
import * as types from '../mutation-types'

export const getLineBotAccountLinkToken = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'getLineBotAccountLinkToken')

  return new Promise((resolve, reject) => {
    PromoterMember.getLineBotAccountLinkToken(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'getLineBotAccountLinkToken')

        // there's no line_bot_account_link_token.js, directly handle from vue component

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getLineBotAccountLinkToken,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
