import ApplicationPolicyUserModel from './application_policy_user_model'
/**
 * Partner user model
 *
 * @export
 * @class Partner
 */
export default class Partner extends ApplicationPolicyUserModel {
  constructor(jwtPayload) {
    super(jwtPayload)
  }
}
