import * as types from '../mutation-types'

export const fetchShares = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'fetchShares')

  return new Promise((resolve, reject) => {
    model
      .fetchShares(options)
      .then((response) => {
        if (options.forSearch) {
          dispatch(
            'promoterShares/receiveResourcesFromRelationshipsForSearch',
            response,
            {
              root: true
            }
          )
        } else {
          dispatch(
            'promoterShares/receiveResourcesFromRelationships',
            response,
            {
              root: true
            }
          )
        }

        dispatch(
          'promoters/receiveResourcesFromRelationshipsWithReplace',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterMembers/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterShareLevels/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('promoterLevels/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'promoterPreferredCategoryShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'productCategories/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchShares')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchShares,
            ref: {
              dispatch,
              commit
            },
            params: {
              model: model,
              options: options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
