import * as types from '../mutation-types'

export const batchSaveShareProductShips = (
  { dispatch, commit },
  { model, promoterEventProductShpIds, promoterShareIds, options }
) => {
  commit(types.API_REQUEST_START, 'batchSaveShareProductShips')

  return new Promise((resolve, reject) => {
    model
      .batchSaveShareProductShips({
        promoterEventProductShpIds: promoterEventProductShpIds,
        promoterShareIds: promoterShareIds,
        options: options
      })
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'batchSaveShareProductShips')

        dispatch(
          'promoterShareProductShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: batchSaveShareProductShips,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              shareProductShips
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const deleteShareProductShip = (
  { dispatch, commit },
  { model, shareProductShip }
) => {
  commit(types.API_REQUEST_START, 'deleteShareProductShip')

  return new Promise((resolve, reject) => {
    model
      .deleteShareProductShip(shareProductShip)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'deleteShareProductShip')

        dispatch(
          'promoterShareProductShips/deleteResourceFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(shareProductShip.id)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: deleteShareProductShip,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              productShip
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
