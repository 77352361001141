import PromoterShare from '../../resource_models/promoter_share'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new PromoterShare(state.entities[id]))
}

export const allForSearch = (state) => {
  return state.searchResult.map((id) => new PromoterShare(state.entities[id]))
}

export const find = (state) => (id) => {
  return new PromoterShare(state.entities[id])
}

export const findBy = (state) => (attributes) => {
  return new PromoterShare(
    Object.keys(state.entities)
      .map((key) => {
        return state.entities[key]
      })
      .find((record) => {
        return Object.keys(attributes).every((key) => {
          return record[key] === attributes[key]
        })
      })
  )
}

export const meta = (state) => {
  return state.meta
}

/**
 * 回傳 API response 中的 searchMeta 資訊
 *
 * @returns {object} searchMeta info from API response
 */
export const searchMeta = (state) => {
  return state.searchMeta
}

export const errors = (state) => {
  return state.errors
}

export const currentKolShare = (state) => {
  return find(state)(state.currentKolShareId)
}

export const dashboardData =
  (state) =>
  (scope = null) => {
    if (scope) {
      return state.dashboard[scope]
    } else {
      return state.dashboard
    }
  }

export const descendants = (state) => (thisPromoterShare) => {
  return state.result
    .map((id) => new PromoterShare(state.entities[id]))
    .filter((promoterShare) => {
      return (
        promoterShare.lft > thisPromoterShare.lft &&
        promoterShare.rgt < thisPromoterShare.rgt
      )
    })
}

export const template = (state) => {
  return state.result
    .map((id) => new PromoterShare(state.entities[id]))
    .find((promoterShare) => {
      return promoterShare.is_template
    })
}

export const statsData =
  (state) =>
  (scope = null) => {
    return state.stats[scope] ? state.stats[scope].data : []
  }

export const statsMeta =
  (state) =>
  (scope = null) => {
    return state.stats[scope] ? state.stats[scope].meta : null
  }
