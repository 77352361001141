import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'product_variant_integrations',
  attributes: ['id', 'variant_id', 'source_id', 'quantity'],
  editableAttributes: []
}

export default class ProductVariantIntegration extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
