import * as types from '../mutation-types'

export const fetchWithdrawalRequests = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchWithdrawalRequests')

  return new Promise((resolve, reject) => {
    model
      .fetchWithdrawalRequests(options)
      .then((response) => {
        dispatch(
          'promoterWithdrawalRequests/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterWithdrawalTransactions/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchWithdrawalRequests')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchWithdrawalRequests,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const payWithdrawalRequest = (
  { dispatch, commit },
  { model, withdrawalRequest }
) => {
  commit(types.API_REQUEST_START, 'payWithdrawalRequest')

  return new Promise((resolve, reject) => {
    model
      .payWithdrawalRequest({
        withdrawalRequest
      })
      .then((response) => {
        dispatch(
          'promoterWithdrawalRequests/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'payWithdrawalRequest')

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: payWithdrawalRequest,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              withdrawalRequest
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
