import * as types from '../mutation-types'
import Product from '../../../resource_models/product'

export const fetchVideos = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'fetchVideos')

  return new Promise((resolve, reject) => {
    Product.fetchVideos(options)
      .then((response) => {
        commit(types.FETCH_PRODUCT_VIDEOS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchVideos,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const uploadVideo = ({ dispatch, commit }, { product, formData }) => {
  commit(types.API_REQUEST_START, 'uploadVideo')

  return new Promise((resolve, reject) => {
    product
      .uploadVideo(formData)
      .then((response) => {
        commit(types.UPLOAD_PRODUCT_VIDEO_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: uploadVideo,
            ref: {
              dispatch,
              commit
            },
            params: {
              product,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const downloadVideoFromCloudinary = ({ dispatch, commit }, video) => {
  commit(types.API_REQUEST_START, 'downloadVideoFromCloudinary')

  return new Promise((resolve, reject) => {
    Product.downloadVideoFromCloudinary(video)
      .then((response) => {
        commit(types.GET_PRODUCT_VIDEO_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: downloadVideoFromCloudinary,
            ref: {
              dispatch,
              commit
            },
            params: video
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroyVideo = ({ dispatch, commit }, video) => {
  commit(types.API_REQUEST_START, 'destroyVideo')

  return new Promise((resolve, reject) => {
    Product.destroyVideo(video)
      .then((response) => {
        commit(types.DELETE_PRODUCT_VIDEO_SUCCESS, video.id)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroyVideo,
            ref: {
              dispatch,
              commit
            },
            params: video
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
