import * as types from '../mutation-types'

// products
export const fetchProducts = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchProducts')

  return new Promise((resolve, reject) => {
    model
      .fetchProducts(options)
      .then((response) => {
        if (options.replace) {
          dispatch(
            'products/receiveResourcesFromRelationshipsWithReplace',
            response,
            {
              root: true
            }
          )
        } else {
          dispatch('products/receiveResourcesFromRelationships', response, {
            root: true
          })
        }

        commit(types.API_REQUEST_SUCCESS, 'fetchProducts')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchProducts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const findProduct = ({ dispatch, commit }, { model, productId }) => {
  commit(types.API_REQUEST_START, 'findProduct')

  return new Promise((resolve, reject) => {
    model
      .findProduct(productId)
      .then((response) => {
        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        dispatch(
          'productOptionTypes/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        dispatch('productImages/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'findProduct')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: findProduct,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              productId
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
