import axios from 'axios'
import moment from 'moment'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import FetchingStatsDataOptionsService from '../services/fetch_stats_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_events',
  attributes: [
    'id',
    'mode',
    'promoter_id',
    'created_at',
    'description',
    'ended_at',
    'is_enabled',
    'is_locked_profit_share_rule',
    'name',
    'started_at',
    'updated_at',
    'product_ships',
    'share_ranges',
    'shares',
    'cover',
    'summary',
    'token',
    'default_profit_ratio',
    'default_profit_calculate_method',
    'profit_share_rule',
    'is_default',
    'sign_up_token'
  ],
  editableAttributes: [
    'mode',
    'name',
    'promoter_id',
    'started_at',
    'ended_at',
    'is_enabled',
    'is_locked_profit_share_rule',
    'description',
    'uploaded_attachment_ids',
    'summary',
    'default_profit_ratio',
    'default_profit_calculate_method',
    'profit_share_rule',
    'source_promoter_event_id',
    'is_default'
  ]
}

export default class PromoterEvent extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static modes = { normal: 0, default: 1, kol: 2 }

  static kolcenterOverallStatsDate = '2022-06-01'

  /**
   * 從 server 取得所有 promoter event 可用的 rules 列表
   *
   * @returns {promise}
   */
  static profitShareRules() {
    return axios.get(`${new this().apiBasePath()}/profit_share_rules`)
  }

  static defaultProfitCalculateMethods() {
    return axios.get(
      `${new this().apiBasePath()}/default_profit_calculate_methods`
    )
  }

  updateCover(formData) {
    return axios.put(`${this.apiBasePath()}/${this.id}/cover`, formData)
  }

  removeCover() {
    return axios.delete(`${this.apiBasePath()}/${this.id}/cover`)
  }

  hasCover() {
    return this.cover && this.cover.url !== null
  }

  toggleEnabled() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_enabled`)
  }

  toggleEnabledProfitShare() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/toggle_enabled_profit_share`
    )
  }

  toggleLockedProfitShareRule() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/toggle_locked_profit_share_rule`
    )
  }

  static fetchDashboardData(dataType, options) {
    let optionsQueryString = Object.keys(options)
      .map((key) => `${key}=${options[key]}`)
      .join('&')
    return axios.get(
      `${new this().apiBasePath()}/dashboard/${dataType}?${optionsQueryString}`
    )
  }

  static fetchStats(options = {}) {
    let queryString = FetchingStatsDataOptionsService.call(options)

    return axios.get(`${new this().apiBasePath()}/stats?${queryString}`)
  }

  static getKolcenter() {
    return axios.get(`${new this().apiBasePath()}/kolcenter`)
  }

  // default event
  static getDefaultEvent() {
    return axios.get(`${new this().apiBasePath()}/default_event`)
  }

  static revokeSignUpToken() {
    return axios.post(`${new this().apiBasePath()}/revoke_sign_up_token`)
  }

  // eventProductShips handlers
  fetchEventProductShips(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/event_product_ships?${FetchingDataOptionsService.call(options)}`
    )
  }

  batchSaveEventProductShips(productShips) {
    const requestBody = {
      data: {
        type: 'batch-save-promoter-event-prodcut-ships',
        attributes: {
          product_ships: productShips
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/event_product_ships/batch_save`,
      requestBody
    )
  }

  // productShips handlers, will be replaced by eventProductShips
  createProductShips(productIds) {
    const requestBody = {
      data: {
        type: 'promoter-event-product-ships',
        attributes: {
          product_ids: productIds
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/product_ships`,
      requestBody
    )
  }

  updateProductShips(productShips) {
    const requestBody = {
      data: {
        type: 'promoter-event-product-ships',
        attributes: {
          product_ships: productShips
        }
      }
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/product_ships`,
      requestBody
    )
  }

  deleteProductShips(productship) {
    return axios.delete(
      `${this.apiBasePath()}/${this.id}/product_ships/${productship.id}`
    )
  }

  // share product ships
  batchSaveShareProductShips({
    promoterEventProductShpIds,
    promoterShareIds,
    options
  }) {
    const requestBody = {
      data: {
        type: 'batch-save-promoter-share-prodcut-ships',
        attributes: {
          event_product_ship_ids: promoterEventProductShpIds,
          share_ids: promoterShareIds
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/share_product_ships/batch_save`,
      requestBody
    )
  }

  deleteShareProductShip(shareProductShip) {
    return axios.delete(
      `${this.apiBasePath()}/${this.id}/share_product_ships/${
        shareProductShip.id
      }`
    )
  }

  fetchProducts(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/products?${FetchingDataOptionsService.call(options)}`
    )
  }

  findProduct(productId) {
    return axios.get(`${this.apiBasePath()}/${this.id}/products/${productId}`)
  }

  // shareRanges handlers

  createShareRange({ lowerPrice, profitRatio }) {
    const requestBody = {
      data: {
        type: 'promoter-event-share-ranges',
        attributes: {
          lower_price: lowerPrice,
          profit_ratio: profitRatio
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/share_ranges`,
      requestBody
    )
  }

  updateShareRanges(shareRanges) {
    const requestBody = {
      data: {
        type: 'promoter-event-share-ranges',
        attributes: {
          share_ranges: shareRanges
        }
      }
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/share_ranges`,
      requestBody
    )
  }

  deleteShareRange(shareRange) {
    return axios.delete(
      `${this.apiBasePath()}/${this.id}/share_ranges/${shareRange.id}`
    )
  }

  // order_ships
  fetchOrders(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/orders?${FetchingDataOptionsService.call(options)}`
    )
  }

  fetchOrderStats(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    queryString += `&x_field=${options.x_field}`

    options.y_fields.forEach((yField) => {
      queryString += `&y_fields[]=${yField}`
    })

    return axios.get(
      `${this.apiBasePath()}/${this.id}/orders/stats?${queryString}`
    )
  }

  // duplicate
  duplicate() {
    return axios.post(`${this.apiBasePath()}/duplicate`, this.requestBody())
  }

  // shares
  fetchShares(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/shares?${FetchingDataOptionsService.call(options)}`
    )
  }

  // campaigns
  fetchCampaigns(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/campaigns?${FetchingDataOptionsService.call(options)}`
    )
  }

  createCampaign(campaign) {
    const requestBody = {
      data: {
        type: 'promoter-event-campaign',
        attributes: campaign
      }
    }

    return axios.post(`${this.apiBasePath()}/${this.id}/campaigns`, requestBody)
  }

  updateCampaign(campaign) {
    const requestBody = {
      data: {
        type: 'promoter-event-campaign',
        attributes: campaign
      }
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/campaigns`, requestBody)
  }

  destroyCampaign(id) {
    return axios.delete(`${this.apiBasePath()}/${this.id}/campaigns/${id}`)
  }

  // groups
  fetchGroups(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/groups?${FetchingDataOptionsService.call(options)}`
    )
  }

  // level
  fetchLevels(options) {
    return axios.get(`${this.apiBasePath()}/${this.id}/levels`)
  }

  updateLevel(level) {
    const requestBody = {
      data: {
        type: 'promoter-level',
        attributes: level
      }
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/levels/${level.id}`,
      requestBody
    )
  }

  updateLevelImage(level, formData) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/levels/${level.id}/image`,
      formData
    )
  }

  // withdrawal_requests
  fetchWithdrawalRequests(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/withdrawal_requests?${FetchingDataOptionsService.call(options)}`
    )
  }

  // withdrawal_transactions
  fetchWithdrawalTransactions(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/withdrawal_transactions?${FetchingDataOptionsService.call(options)}`
    )
  }

  acceptWithdrawalTransaction({ id }) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/withdrawal_transactions/${id}/accept`
    )
  }

  generateWithdrawalTransactions({ withdrawal_request_ids }) {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/generate_withdrawal_transactions`,
      {
        withdrawal_request_ids: withdrawal_request_ids
      }
    )
  }
}
