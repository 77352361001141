import * as types from '../mutation-types'
import PromoterMember from '@models/promoter_member'

export const fetchCurrentBuyoutOrder = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchCurrentBuyoutOrder')

  return new Promise((resolve, reject) => {
    PromoterMember.fetchCurrentBuyoutOrder()
      .then((response) => {
        dispatch('buyoutOrders/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'buyoutOrderItems/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'fetchCurrentBuyoutOrder')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchCurrentBuyoutOrder,
            ref: {
              dispatch,
              commit
            },
            params: null
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const completeCurrentBuyoutOrder = ({ dispatch, commit }, { form }) => {
  commit(types.API_REQUEST_START, 'completeCurrentBuyoutOrder')

  return new Promise((resolve, reject) => {
    PromoterMember.completeCurrentBuyoutOrder({ form })
      .then((response) => {
        dispatch('buyoutOrders/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'buyoutOrderItems/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'completeCurrentBuyoutOrder')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: completeCurrentBuyoutOrder,
            ref: {
              dispatch,
              commit
            },
            params: { form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchBuyoutOrders = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'fetchBuyoutOrders')

  return new Promise((resolve, reject) => {
    model
      .fetchBuyoutOrders({ options })
      .then((response) => {
        dispatch('buyoutOrders/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'buyoutOrderItems/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'fetchBuyoutOrders')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchBuyoutOrders,
            ref: {
              dispatch,
              commit
            },
            params: { options }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
