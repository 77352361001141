import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'users',
  attributes: [
    'created_at',
    'email',
    'id',
    'last_sign_in_at',
    'sign_in_count',
    'updated_at',
    'member_level',
    'comments',
    'profile',
    'name',
    'phone',
    'children',
    'bonus_credit',
    'level_info',
    'auths',
    'life_time_value',
    'last_paid_at',
    'member_level_id'
  ],
  editableAttributes: [
    'email',
    'new_password',
    'password',
    'password_confirmation',
    'profile',
    'children'
  ]
}

export default class User extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static unbindAuthService(authId) {
    return axios.delete(`${new this().apiBasePath()}/auths/${authId}`)
  }

  static check(email) {
    const requestBody = {
      data: {
        type: 'user-check',
        attributes: {
          email
        }
      }
    }

    return axios.post(`${new this().apiBasePath()}/check`, requestBody)
  }

  static getCollections(latestUpdatedAt = 0) {
    return axios.get(
      `${new this().apiBasePath()}/collections?latest_updated_at=${latestUpdatedAt}`
    )
  }

  static fetchRecentOrders(options = {}) {
    return axios.get(
      `${new this().apiBasePath()}/recent_orders?${FetchingDataOptionsService.call(
        options
      )}`
    )
  }

  static fetchOrders(options = {}) {
    return axios.get(
      `${new this().apiBasePath()}/orders?${FetchingDataOptionsService.call(
        options
      )}`
    )
  }

  static getOrder(token) {
    return axios.get(`${new this().apiBasePath()}/orders/${token}`)
  }

  static applyRma(orderToken, returnAuthorization) {
    const requestBody = {
      data: {
        type: 'return_authorizations',
        attributes: {
          items: returnAuthorization.items,
          reason_id: returnAuthorization.reason_id,
          note: returnAuthorization.note
        }
      }
    }

    return axios.post(
      `${new this().apiBasePath()}/orders/${orderToken}/apply_rma`,
      requestBody
    )
  }

  static getGuestOrder(token, jwt) {
    return axios.get(
      `${new this().apiBasePath({
        withResourceType: false
      })}/users/guest_orders/${token}?jwt=${jwt}`
    )
  }

  static currentOrder() {
    return axios.get(`${new this().apiBasePath()}/current_order`)
  }

  static currentOrderItems() {
    return axios.get(`${new this().apiBasePath()}/current_order/items`)
  }

  static addItemToCart(requestBody) {
    return axios.post(
      `${new this().apiBasePath()}/current_order/items`,
      requestBody
    )
  }

  static updateCartItem(cartItem) {
    return axios.put(`${new this().apiBasePath()}/current_order/items`, {
      data: {
        type: 'order_items',
        attributes: {
          order_item_id: cartItem.id,
          variant_id: cartItem.variant_id,
          quantity: cartItem.quantity
        }
      }
    })
  }

  static removeCartItem(cartItem) {
    return axios.delete(`${new this().apiBasePath()}/current_order/items`, {
      data: {
        data: {
          type: 'order_items',
          attributes: {
            order_item_id: cartItem.id
          }
        }
      }
    })
  }

  static fetchCommonAddresses() {
    return axios.get(`${new this().apiBasePath()}/common_addresses`)
  }

  static destroyCommonAddress(commonAddressId) {
    return axios.delete(
      `${new this().apiBasePath()}/common_addresses/${commonAddressId}`
    )
  }

  fetchBonusCreditTransactions(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/bonus_credit_transactions?${FetchingDataOptionsService.call(options)}`
    )
  }

  static fetchCreditCards() {
    return axios.get(`${new this().apiBasePath()}/credit_cards`)
  }

  static confirmItems(order) {
    const requestBody = {
      data: {
        type: 'confirm_order_items',
        attributes: {
          shipments: order.shipments,
          enable_bonus_deduction: order.enable_bonus_deduction
        }
      }
    }

    return axios.post(
      `${new this().apiBasePath()}/current_order/confirm_items`,
      requestBody
    )
  }

  static placeOrder(order) {
    const requestBody = {
      data: {
        type: 'orders',
        attributes: {
          email: order.email,
          buyer_name: order.buyer_name,
          buyer_phone: order.buyer_phone,
          notes: order.notes,
          shipments: order.shipments,
          is_same_as_billing_address: order.is_same_as_billing_address,
          billing_address: order.billing_address,
          save_shipping_address_as_common:
            order.save_shipping_address_as_common,
          save_billing_address_as_common: order.save_billing_address_as_common,
          is_agree_with_term_of_service: order.is_agree_with_term_of_service,
          is_agree_to_join_membership: order.is_agree_to_join_membership,
          is_agree_to_receive_news_letters:
            order.is_agree_to_receive_news_letters,
          payment_method_id: order.payment_method_id,
          user_credit_card_id: order.user_credit_card_id
        }
      }
    }
    return axios.post(
      `${new this().apiBasePath()}/current_order/place_order`,
      requestBody
    )
  }

  static applyCoupon(code) {
    return axios.post(
      `${new this().apiBasePath()}/current_order/apply_coupon`,
      {
        data: {
          type: 'apply_coupon',
          attributes: {
            coupon_code: code
          }
        }
      }
    )
  }

  static removeCoupon(code) {
    return axios.delete(
      `${new this().apiBasePath()}/current_order/apply_coupon`,
      {
        data: {
          data: {
            type: 'remove_coupon',
            attributes: {
              coupon_code: code,
              is_remove: true
            }
          }
        }
      }
    )
  }

  static useBonus(bonusCredits) {
    return axios.post(`${new this().apiBasePath()}/current_order/use_bonus`, {
      data: {
        type: 'use_bonus',
        attributes: {
          bonus_credits: bonusCredits
        }
      }
    })
  }

  static removeBonusDeduction() {
    return axios.delete(`${new this().apiBasePath()}/current_order/use_bonus`)
  }

  static purchaseAgain(sourceOrderToken) {
    const requestBody = {
      data: {
        type: 'purchase_again',
        attributes: {
          source_order_token: sourceOrderToken
        }
      }
    }
    return axios.patch(
      `${new this().apiBasePath()}/current_order/purchase_again`,
      requestBody
    )
  }

  fetchChildren(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/children?${FetchingDataOptionsService.call(options)}`
    )
  }

  static fetchReturnAuthorizations(options = {}) {
    return axios.get(
      `${new this().apiBasePath()}/return_authorizations?${FetchingDataOptionsService.call(
        options
      )}`
    )
  }

  static getReturnAuthorization(number) {
    return axios.get(
      `${new this().apiBasePath()}/return_authorizations/${number}`
    )
  }

  updateProfile() {
    const body = {
      id: this.id,
      data: {
        type: 'user_profiles',
        attributes: this.profile
      }
    }
    return axios.put(`${this.apiBasePath()}/profile`, body)
  }

  updateBonusCreditTransaction(action, bonus_credit_transactions) {
    const requestBody = {
      data: {
        type: 'user_bonus_credit_transactions',
        attributes: {
          credit_amount: bonus_credit_transactions.credit_amount,
          reason: bonus_credit_transactions.reason,
          action: action
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/bonus_credit_transactions`,
      requestBody
    )
  }

  getPhoneVerificationCodeSms() {
    const body = {
      id: this.id,
      data: {
        type: 'user_profiles',
        attributes: this.profile
      }
    }

    return axios.post(
      `${this.apiBasePath()}/verify_phone/verification_code`,
      body
    )
  }

  verifyPhone(verification_code) {
    const body = {
      id: this.id,
      data: {
        type: 'user_profiles',
        attributes: {
          verification_code: verification_code
        }
      }
    }

    return axios.put(`${this.apiBasePath()}/verify_phone`, body)
  }

  static fetchDashboardData(dataType, options) {
    let optionsQueryString = Object.keys(options)
      .map((key) => `${key}=${options[key]}`)
      .join('&')
    return axios.get(
      `${new this().apiBasePath()}/dashboard/${dataType}?${optionsQueryString}`
    )
  }

  fetchResetPasswordInfo() {
    return axios.get(`${this.apiBasePath()}/${this.id}/reset_password_info`)
  }

  generateResetPasswordInfo(options = {}) {
    return axios.put(`${this.apiBasePath()}/${this.id}/reset_password_info`)
  }

  fetchOrdersWithCreatedValue() {
    return axios.get(
      `${this.apiBasePath()}/${this.id}/orders_with_created_value`
    )
  }

  sendResetPasswordInfo(phone) {
    const body = {
      id: this.id,
      data: {
        type: 'user',
        attributes: {
          phone: phone
        }
      }
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/reset_password_info/send_sms`,
      body
    )
  }

  static searchOrder(email, orderNumber) {
    const body = {
      data: {
        type: 'search-orders',
        attributes: {
          number: orderNumber,
          email: email
        }
      }
    }

    return axios.post(
      `${new this().apiBasePath({
        withResourceType: false
      })}/users/search_order`,
      body
    )
  }

  // extra methods or helpers here...

  fullAddress() {
    let address = this.profile.address
    return `${address.zip || ''} ${address.city || ''} ${address.area || ''} ${
      address.street_address || ''
    }`
  }

  /**
   * @returns {String}
   */
  get nameOrEmail() {
    return this.name || this.email
  }

  phoneVerifiedType() {
    return {
      'is-success': this.profile.phone_verified,
      'is-danger': !this.profile.phone_verified
    }
  }

  get oddPayApiBasePath() {
    return `${OPTIONS.apiPath}/${OPTIONS.apiVersion}/${OPTIONS.scope}/odd_pay/users`
  }

  static fetchOddPayInvoices(options = {}) {
    return axios.get(
      `${
        new this().oddPayApiBasePath
      }/invoices?${FetchingDataOptionsService.call(options)}`
    )
  }

  static fetchCurrentSubscriptionInvoice() {
    return axios.get(
      `${new this().oddPayApiBasePath}/invoices/current_subscription`
    )
  }

  static assignVariantToOddPayInvoice(oddPayInvoice) {
    const requestBody = {
      data: {
        type: 'odd_pay_invoices',
        attributes: {
          variant_id: oddPayInvoice.variant_id,
          quantity: oddPayInvoice.quantity
        }
      }
    }
    return axios.put(
      `${new this().oddPayApiBasePath}/invoices/${
        oddPayInvoice.id
      }/assign_variant`,
      requestBody
    )
  }

  static confirmOddPayInvoice(oddPayInvoice) {
    const requestBody = {
      data: {
        type: 'odd_pay_invoices',
        attributes: {
          email: oddPayInvoice.email,
          name: oddPayInvoice.name,
          contact_phone: oddPayInvoice.contact_phone,
          company_name: oddPayInvoice.company_name,
          company_ein: oddPayInvoice.company_ein,
          address: oddPayInvoice.address,
          note: oddPayInvoice.note
        }
      }
    }
    return axios.put(
      `${new this().oddPayApiBasePath}/invoices/${oddPayInvoice.id}/confirm`,
      requestBody
    )
  }

  static makePaymentForOddPayInvoice(oddPayInvoice) {
    const requestBody = {
      data: {
        type: 'odd_pay_invoices',
        attributes: {
          payment_method_id: oddPayInvoice.payment_method_id
        }
      }
    }
    return axios.put(
      `${new this().oddPayApiBasePath}/invoices/${
        oddPayInvoice.id
      }/make_payment`,
      requestBody
    )
  }
}
