<template lang="pug">
b-select.k-shared-select(
  :class="containerClass"
  :value="value"
  :placeholder="placeholder"
  :size="size"
  :expanded="expanded"
  :disabled="disabled"
  @input="inputHandler"
)
  slot
</template>

<script setup>
import { computed } from 'vue'

const STYLE_TYPE_MAP = {
  kolcenter: 'kolcenter-type'
}

const props = defineProps({
  value: { type: [String, Number] },
  size: { type: String },
  placeholder: { type: String },
  expanded: { type: Boolean },
  disabled: { type: Boolean },

  styleType: { type: String }
})

const emit = defineEmits(['input'])

const inputHandler = (value) => {
  emit('input', value)
}

const containerClass = computed(() => {
  return [STYLE_TYPE_MAP[props.styleType]].filter(Boolean).join(' ')
})
</script>

<style lang="sass">
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/size'

.k-shared-select
  &.kolcenter-type
    .select
      width: 100%
      height: 44px
      select
        width: 100%
        height: 100%
        border: 1px solid color.$white-lilac
        border-radius: size.$radius-normal
        text-align: center
        font-size: font.$normal
        font-weight: font.$semibold
        color: color.$ci
      &:after
        border-color: color.$ci!important
</style>
