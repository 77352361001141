import i18n from 'i18n-js'
import moment from 'moment'

export const useI18n = () => {
  return {
    /**
     * 取得 Rails model attributes 的 i18n 值
     *
     * @param {string} model model name
     * @param {string} attribute attribute name
     * @param {Object} options assign locals
     * @returns {any}
     */
    attributeLocaleText(model, attribute, options = {}) {
      return i18n.t(`activerecord.attributes.${model}.${attribute}`, options)
    },

    /**
     * 取得 Rails model name 的 i18n 值
     *
     * @param {string} model model name
     * @param {boolean} isPlural 是否為複數（預設為 false）
     * @returns {string}
     */
    modelNameLocaleText(model, isPlural = false) {
      if (isPlural) {
        return i18n.t(`activerecord.models.${model}.others`)
      } else {
        return i18n.t(`activerecord.models.${model}.one`)
      }
    },

    /**
     * 取得 Rails model 中 enums 的 i18n 值
     *
     * @param {string} model model name
     * @param {string} enumAttribute enum 欄位的名稱
     * @param {string} key enum 值的名稱
     * @param {Object} options assign locals
     * @returns {string}
     */
    enumLocaleText(model, enumAttribute, key, options = {}) {
      return i18n.t(`enums.${model}.${enumAttribute}.${key}`, options)
    },

    /**
     * 取得 Rails model 中 aasm state 的 i18n 值
     *
     * @param {string} model model name
     * @param {string} aasmAttribute 狀態的名稱
     * @param {string} state 狀態值的名稱
     * @returns {string}
     */
    aasmStateLocaleText(model, aasmAttribute, state) {
      return i18n.t(`aasm_state.${model}.${aasmAttribute}.${state}`)
    },

    /**
     * 取得 Rails model 中 public activity 的 i18n 值
     *
     * @param {string} model model name
     * @param {string} activity 活動的名稱
     * @param {Object} options 若需要可帶入 locals 物件
     * @returns {string}
     */
    activityLocaleText(model, activity, options = {}) {
      return i18n.t(`activity.${model}.${activity}`, options)
    },

    /**
     * 取得頁面標題的 i18n 值
     *
     * @param {string} scope 頁面操作角色（admin, client 等）
     * @param {string} pageName 頁面名稱
     * @param {string} subPageName 副頁面名稱
     * @returns {string}
     */
    pageTitleLocaleText(scope, pageName, subPageName) {
      return i18n.t(`page_titles.${scope}.${pageName}.${subPageName}`)
    },

    /**
     * 取得 action 的 i18n 值
     *
     * @param {string} action 動作的名稱
     * @param {Object} locals assign locals
     * @returns {string}
     */
    actionLocaleText(action, locals = {}) {
      return i18n.t(`actions.${action}`, locals)
    },

    /**
     * 取得 TimeAgo 相對時間的 i18n 值
     *
     * @param {number|Date} datetime
     * @returns {string}
     */
    timeAgoLocaleText(datetime) {
      if (datetime instanceof Date) {
        return moment(datetime).fromNow()
      } else {
        return moment(datetime, 'X').fromNow()
      }
    },

    /**
     * 取得自訂格式的時間字串
     *
     * @param {number|Date} datetime
     * @param {String} format
     * @returns {string}
     */
    dateTimeLocaleText(
      datetime,
      format = 'YYYY MMMDo HH:mm:ss',
      locale = 'zh-TW'
    ) {
      if (!datetime) return '-'

      if (datetime instanceof Date) {
        return moment(datetime).locale(locale).format(format)
      } else {
        return moment(datetime, 'X').locale(locale).format(format)
      }
    },

    /**
     * 取得 message 的 i18n 值
     *
     * @param {string} message
     * @param {string} locals 可傳入變數
     * @returns {string}
     */
    messageLocaleText(message, locals = {}) {
      return i18n.t(`messages.${message}`, locals)
    },

    /**
     * 取得 copies 的 i18n 值
     *
     * @param {string} copy
     * @param {string} locals 可傳入變數
     * @returns {string}
     */
    copyLocaleText(message, locals = {}) {
      return i18n.t(`copies.${message}`, locals)
    },

    systemLocaleText(message, locals = {}) {
      return i18n.t(`systems.${message}`, locals)
    },

    localeTextFor(scope, locals = {}) {
      return i18n.t(scope, locals)
    }
  }
}

export default useI18n
