import * as types from '../mutation-types'

export const fetchLevels = ({ dispatch, commit }, { model, options = {} }) => {
  commit(types.API_REQUEST_START, 'fetchLevels')

  return new Promise((resolve, reject) => {
    model
      .fetchLevels(options)
      .then((response) => {
        dispatch('promoterLevels/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'fetchLevels')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchLevels,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateLevel = ({ dispatch, commit }, { model, level }) => {
  commit(types.API_REQUEST_START, 'updateLevel')

  return new Promise((resolve, reject) => {
    model
      .updateLevel(level)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'updateLevel')

        dispatch('promoterLevels/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        level.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateLevel,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              level
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateLevelImage = (
  { dispatch, commit },
  { model, prmoterLevel, formData }
) => {
  commit(types.API_REQUEST_START, 'updateLevelImage')

  return new Promise((resolve, reject) => {
    model
      .updateLevelImage(prmoterLevel, formData)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, response)

        dispatch('promoterLevels/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateLevelImage,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
