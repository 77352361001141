import Money from 'odd-money'
import customCurrencies from '../custom_currencies.js'
import useRestoreScrollPosition from '../composables/use_restore_scroll_position'

const SignedInScopeTypeMap = {
  user: 'User',
  admin: 'Admin',
  promoter_member: 'PromoterMember',
  partner: 'Partner'
}

function install(
  Vue,
  options = {
    envScope: 'user'
  }
) {
  const { tryRestoreScrollPosition } = useRestoreScrollPosition()

  Vue.mixin({
    computed: {
      currentUser() {
        return this.$store.getters['currentUser']
      },

      isUserSignedIn() {
        return this.currentUser.type === SignedInScopeTypeMap[options.envScope]
      },

      currencyReferenceConfig() {
        return this.$store.getters['users/currencyReferenceConfig']
      },

      currentCurrency() {
        return this.currencyReferenceConfig?.selectedCurrency || 'TWD'
      }
    },

    methods: {
      tryRestoreScrollPosition,
      simpleFormat(text) {
        if (text) {
          return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
        }
      },

      toMoney(
        cents,
        options = {
          isExchange: true,
          currency: this.currentCurrency,
          customCurrencies: null
        }
      ) {
        if (options.currency === 'TWD' || !options.isExchange)
          return new Money(cents, 'TWD', options.customCurrencies)

        cents =
          Math.round(
            (cents * this.currencyReferenceConfig.currencyRate) / 100
          ) * 100

        return new Money(cents, options.currency, options.customCurrencies)
      },

      moneyFromAmount(amount, currency = 'TWD') {
        return Money.fromAmount(amount, currency, customCurrencies)
      },

      currentUnixTime() {
        return Math.floor(Date.now() / 1000)
      }
    }
  })
}

export default install
