import localforage from 'localforage'

export const useLocalForage = () => {
  return {
    getItem: async (key) => await localforage.getItem(key),
    setItem: async (key, value) => await localforage.setItem(key, value),
    removeItem: async (key) => await localforage.removeItem(key),
    clearStorage: async () => await localforage.clear(),
    storageLength: async () => await localforage.length(),
    storageKeys: async () => await localforage.keys()
  }
}

export default useLocalForage
