<template lang="pug">
b-button.vc-k-button(
  :tag="href ? 'a' : 'button'"
  :class="buttonClass"
  :href="href"
  :expanded="expanded"
  :disabled="disabled"
  :size="size"
  :loading="loading"
  :icon-left="iconLeft"
  :icon-right="iconRight"
  :data-behavior="dataBehavior"
  @click="clickHandler"
)
  slot
</template>

<script>
import { defineComponent, computed } from 'vue'

const COLOR_MAP = {
  'is-default': '',
  'is-white': 'is-white',
  'is-black': 'is-black',
  'is-light': 'is-light',
  'is-dark': 'is-dark',
  'is-primary': 'is-primary',
  'is-info': 'is-info',
  'is-success': 'is-success',
  'is-warning': 'is-warning',
  'is-danger': 'is-danger',
  'is-submit': 'is-primary',
  'is-edit': 'is-primary',
  'is-delete': 'is-danger',
  'is-cancel': '',
  // below is kolcenter button
  light: 'light-button',
  shadow: 'is-shadow has-no-border',
  'shadow-light': 'is-shadow is-light',
  'ci-blue': 'ci-blue-button',
  'shadow-border': 'is-shadow is-outlined is-ci-blue',
  'ci-pink': 'is-shadow is-outlined is-tickle-me-pink',
  'muted-yellow': 'is-shadow is-muted-yellow',
  'aero-blue': 'is-shadow is-aero-blue',
  'white-transparent': 'is-shadow is-white-transparent has-text-white',
  'light-deep-pink': 'is-shadow is-light has-text-deep-pink',
  'is-warning': 'is-warning',
  'ci-border': 'is-ci-border'
}

export default defineComponent({
  props: {
    type: {
      type: String,
      required: false,
      validator(value) {
        return Object.keys(COLOR_MAP).includes(value)
      }
    },
    href: { type: String },
    expanded: { type: Boolean },
    disabled: { type: Boolean },
    loading: { type: Boolean },
    size: { type: String },
    iconLeft: { type: String },
    iconRight: { type: String },
    dataBehavior: { type: String }
  },

  setup(props, { emit }) {
    const buttonClass = computed(() => {
      return COLOR_MAP[props.type]
    })

    const clickHandler = (event) => {
      emit('click', event)
    }

    return {
      buttonClass,
      clickHandler
    }
  }
})
</script>

<style lang="sass">
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'

.vc-k-button
  &.is-shadow
    border: unset
    box-shadow: size.$shadow-tiny
  &.has-no-border
    border: unset
  &.is-block
    display: block
  &.has-text-semibold
    font-weight: font.$semibold
  &.is-ci-border
    color: color.$ci
    background-color: color.$white
    border-color: color.$button-border
    &:hover
      background-color: color.$ci
      color: color.$white
      border: none
  &.is-ci-blue.is-outlined
    background-color: transparent
    border-color: color.$ci
    color: color.$ci
  &.ci-blue-button
    background-color: color.$ci
    border-color: transparent
    color: color.$white
    &:hover
      background-color: #00adf2
  &.light-button
    background-color: color.$white-smoke
    border-color: transparent
    color: #000000b3
    &:hover
      background-color: #eeeeee
</style>
