import PromoterMember from '../../../resource_models/promoter_member'
import * as types from '../mutation-types'

// update profile
export const updateProfile = ({ dispatch, commit }, { model, promoterId }) => {
  commit(types.API_REQUEST_START, 'updateProfile')

  return new Promise((resolve, reject) => {
    model
      .updateProfile(promoterId)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfile,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              promoterId
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const updateProfileV2 = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'updateProfileV2')

  return new Promise((resolve, reject) => {
    model
      .updateProfileV2(options)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfileV2,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const updateProfileSampleAddress = (
  { dispatch, commit },
  { model, bankAccountInfo }
) => {
  commit(types.API_REQUEST_START, 'updateProfileSampleAddress')

  return new Promise((resolve, reject) => {
    model
      .updateProfileSampleAddress(bankAccountInfo)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_MEMBER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfileSampleAddress,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              bankAccountInfo
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}
