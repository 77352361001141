import ProductVideo from '../../../resource_models/product_video'

/**
 * 依照 server 回傳的排序列出所有 Resource
 * 若沒有 Resource 則不回傳
 *
 * @returns {array} 所有 Resource
 */
export const allVideos = (state) => {
  return state.videoResult.map(
    (id) => new ProductVideo(state.videoEntities[id])
  )
}

/**
 * 以 ID 在 vuex store 中尋找特定 Resource
 *
 * @param {number} id
 * @returns {object} Resource 物件
 */
export const findVideoFor = (state) => (product_id) => {
  return (
    allVideos(state).find(
      (video) => Number(video.product_id) === Number(product_id)
    ) || new ProductVideo()
  )
}
