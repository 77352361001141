import useLocalForage from './use_local_forage'

export const useRestoreScrollPosition = () => {
  const { getItem, setItem, removeItem } = useLocalForage()
  const prevPageInfo = async () => await getItem('prevPage')

  const currentUrl = window.location.href
  let retry = 0

  const findElementAndScrollTo = async (retryLimit) => {
    const info = await prevPageInfo()
    const element = document.querySelector(`#${info.leaveFrom}`)

    if (retry <= retryLimit && !element) {
      setTimeout(() => {
        retry += 1
        findElementAndScrollTo()
      }, 1000)
    }

    if (!!element) {
      element.scrollIntoView({ behavior: 'smooth' })
      retry = 0
      await removeItem('prevPage')
    }
  }

  return {
    /**
     * remember user leave from which point
     * @param {String} elementID HTML Element ID
     * @returns {Promise<void>}
     */
    recordPrevPageInfo: async (elementID) => {
      const prevPageInfo = {
        url: window.location.href,
        leaveFrom: elementID
      }

      await setItem('prevPage', prevPageInfo)
    },
    /**
     * fetch prevPageInfo from indexDB.
     * if current URL equal to prevPageInfo.url, find element by ID every 1 sec until meet retryLimit or element
     * found. if element found, smooth scroll it into view.
     * @param {number} retryLimit retry limit for find element and scroll to
     * @returns {Promise<void>}
     */
    tryRestoreScrollPosition: async (retryLimit = 10) => {
      const info = await prevPageInfo()
      if (!info) return

      if (info.url === currentUrl) await findElementAndScrollTo(retryLimit)
    }
  }
}

export default useRestoreScrollPosition
