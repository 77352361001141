import Promoter from '../../resource_models/promoter'

/**
 * @returns {boolean} 這個 module 是否正在與 API 溝通中
 */
export const isLoading = (state) => {
  return state.isCallingAPI
}

/**
 * 依照 server 回傳的排序列出所有 Resource
 * 若沒有 Resource 則不回傳
 *
 * @returns {array} 所有 Resource
 */
export const all = (state) => {
  return state.result.map((id) => new Promoter(state.entities[id]))
}

export const roots = (state) => {
  return state.result
    .map((id) => new Promoter(state.entities[id]))
    .filter((promoter) => promoter.build_source == 'admin_or_promoter')
    .filter((promoter) => promoter.parent_id == null)
}

export const children = (state) => {
  return (thisPromoter) => {
    return state.result
      .map((id) => new Promoter(state.entities[id]))
      .filter((promoter) => promoter.build_source == 'admin_or_promoter')
      .filter((promoter) => promoter.parent_id == thisPromoter.id)
  }
}

export const defaultEvent = (state) => {
  return state.result
    .map((id) => new Promoter(state.entities[id]))
    .filter((promoter) => promoter.build_source == 'default_event')
}

export const rootsForTemplateShare = (state) => {
  return state.result
    .map((id) => new Promoter(state.entities[id]))
    .filter(
      (promoter) =>
        promoter.parent_id == null && promoter.build_source == 'template_share'
    )
}

export const promotersForPromoterMember = (state) => (promoterMemberId) => {
  return state.result
    .map((id) => new Promoter(state.entities[id]))
    .filter((promoter) => {
      return promoter.promoter_member_id == parseInt(promoterMemberId)
    })
}

export const currentPromoterForMemberPromoter =
  (state) => (promoterMemberId, promoter) => {
    return promotersForPromoterMember(state)(promoterMemberId).find((pmt) =>
      pmt.isOrIsAncestorOf(promoter)
    )
  }

/**
 * 以 ID 在 vuex store 中尋找特定 Resource
 *
 * @param {number} id
 * @returns {Object} Resource 物件
 */
export const find = (state) => (id) => {
  return new Promoter(state.entities[id])
}

export const findBy = (state) => (attributes) => {
  return new Promoter(
    Object.keys(state.entities)
      .map((key) => {
        return state.entities[key]
      })
      .find((record) => {
        return Object.keys(attributes).every((key) => {
          return record[key] === attributes[key]
        })
      })
  )
}

/**
 * 回傳 API response 中的 meta 資訊
 *
 * @returns {Object} meta info from API response
 */
export const meta = (state) => {
  return state.meta
}

/**
 * 回傳 state 中的 errors
 *
 * @returns {Object} Errors 物件
 */
export const errors = (state) => {
  return state.errors
}

export const dashboardData =
  (state) =>
  (scope = null) => {
    if (scope) {
      return state.dashboard[scope]
    } else {
      return state.dashboard
    }
  }

/**
 * 所有可用的 roles 列表
 *
 * @returns {array} 所有 roles
 */
export const roles = (state) => {
  return state.roles
}
