import * as types from '../mutation-types'

export const createWithdrawalTransactions = (
  { dispatch, commit },
  { model, request_ids }
) => {
  commit(types.API_REQUEST_START, 'createWithdrawalTransactions')

  return new Promise((resolve, reject) => {
    model
      .createWithdrawalTransactions({
        request_ids
      })
      .then((response) => {
        dispatch(
          'promoterWithdrawalTransactions/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterWithdrawalRequests/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'createWithdrawalTransactions')

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createWithdrawalTransactions,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              request_ids
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
