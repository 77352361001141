import * as types from '../mutation-types'

export const fetchPreferredCategoryShips = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchPreferredCategoryShips')

  return new Promise((resolve, reject) => {
    model
      .fetchPreferredCategoryShips(options)
      .then((response) => {
        dispatch(
          'promoterPreferredCategoryShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterPreferredCategories/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchPreferredCategoryShips')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchPreferredCategoryShips,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const createPreferredCategoryShip = (
  { dispatch, commit },
  { model, options }
) => {
  commit(types.API_REQUEST_START, 'createPreferredCategoryShip')

  return new Promise((resolve, reject) => {
    model
      .createPreferredCategoryShip(options)
      .then((response) => {
        dispatch(
          'promoterPreferredCategoryShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'createPreferredCategoryShip')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createPreferredCategoryShip,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroyPreferredCategoryShip = (
  { dispatch, commit },
  { model, preferredCategoryShipId }
) => {
  commit(types.API_REQUEST_START, 'destroyPreferredCategoryShip')

  return new Promise((resolve, reject) => {
    model
      .destroyPreferredCategoryShip(preferredCategoryShipId)
      .then((response) => {
        dispatch(
          'promoterPreferredCategoryShips/destroyResourcesFromRelationships',
          preferredCategoryShipId,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'destroyPreferredCategoryShip')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroyPreferredCategoryShip,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
