const DEFAULT_PAGE_SIZE = 25

import FetchingDataOptionsService from 'odd-fetching_data_options_service'

/**
 * 提供 parse 關於 fetching stats data options 的功能
 *
 * @export
 * @class FetchingStatsDataOptionsService
 */
export default class FetchingStatsDataOptionsService {
  static call(options) {
    let result = FetchingDataOptionsService.call(options)

    result += `&x_field=${options.x_field}`

    options.y_fields.forEach((yField) => {
      result += `&y_fields[]=${yField}`
    })

    if (options.query_params) {
      Object.keys(options.query_params).forEach((key) => {
        const config = options.query_params[key]

        Object.keys(config).forEach((subkey) => {
          result += `&query_params[${key}][${subkey}]=${config[subkey]}`
        })
      })
    }

    return result
  }
}
