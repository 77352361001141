import * as types from '../mutation-types'

export const fetchShareProductShips = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchShareProductShips')

  return new Promise((resolve, reject) => {
    model
      .fetchShareProductShips(options)
      .then((response) => {
        dispatch(
          'promoterShareProductShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        dispatch(
          'promoterShareVariantShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterEventProductShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterEventVariantShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch('brands/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('partners/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'promoterCampaigns/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignShareShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchShareProductShips')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchShareProductShips,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchShareProductShipsV2 = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchShareProductShips')

  return new Promise((resolve, reject) => {
    model
      .fetchShareProductShipsV2(options)
      .then((response) => {
        dispatch(
          'promoterShareProductShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        dispatch(
          'promoterShareVariantShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchShareProductShips')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchShareProductShips,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleShareProductShip = (
  { dispatch, commit },
  { model, eventProductShipId }
) => {
  commit(types.API_REQUEST_START, 'toggleShareProductShip')

  return new Promise((resolve, reject) => {
    model
      .toggleShareProductShip({ eventProductShipId: eventProductShipId })
      .then((response) => {
        dispatch(
          'promoterShareProductShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'toggleShareProductShip')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleShareProductShip,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              eventProductShipId
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchBriefShareProductShips = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchBriefShareProductShips')

  return new Promise((resolve, reject) => {
    model
      .fetchBriefShareProductShips(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchBriefShareProductShips')
        dispatch(
          'promoterShareProductShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchBriefShareProductShips,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
