import * as types from '../mutation-types'
import PromoterMember from '@models/promoter_member'

export const createCurrentBuyoutOrderItem = (
  { dispatch, commit },
  { form }
) => {
  commit(types.API_REQUEST_START, 'createCurrentBuyoutOrderItem')

  return new Promise((resolve, reject) => {
    PromoterMember.createCurrentBuyoutOrderItem({ form })
      .then((response) => {
        dispatch('buyoutOrders/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'buyoutOrderItems/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'createCurrentBuyoutOrderItem')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createCurrentBuyoutOrderItem,
            ref: {
              dispatch,
              commit
            },
            params: { form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateCurrentBuyoutOrderItem = (
  { dispatch, commit },
  { id, form }
) => {
  commit(types.API_REQUEST_START, 'updateCurrentBuyoutOrderItem')

  return new Promise((resolve, reject) => {
    PromoterMember.updateCurrentBuyoutOrderItem({ id, form })
      .then((response) => {
        dispatch('buyoutOrders/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'buyoutOrderItems/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'updateCurrentBuyoutOrderItem')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateCurrentBuyoutOrderItem,
            ref: {
              dispatch,
              commit
            },
            params: { id, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroyCurrentBuyoutOrderItem = ({ dispatch, commit }, { id }) => {
  commit(types.API_REQUEST_START, 'destroyCurrentBuyoutOrderItem')

  return new Promise((resolve, reject) => {
    PromoterMember.destroyCurrentBuyoutOrderItem({ id })
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'updateCurrentBuyoutOrderItem')

        dispatch('buyoutOrders/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'buyoutOrderItems/receiveResourcesFromRelationshipsWithReplace',
          response,
          {
            root: true
          }
        )

        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateCurrentBuyoutOrderItem,
            ref: {
              dispatch,
              commit
            },
            params: { id }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
