<template lang="pug">
b-field.k-field-kolcenter(
  :class="containerClass"
  :label="label"
  :type="type"
  :message="message"
  :grouped="grouped"
  :horizontal="horizontal"
  :addons="addons"
)
  template(#label)
    slot(name="label")
  slot
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  label: { type: String },
  type: { type: String },
  message: { type: String },
  grouped: { type: Boolean },
  horizontal: { type: Boolean },
  addons: { type: Boolean },
  required: { type: Boolean },
  skipLabel: { type: Boolean },

  options: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const containerClass = computed(() => {
  return [
    props.required ? 'required' : null,
    props.skipLabel ? 'skip-label' : null,
    props.options.bordered ? 'bordered' : null,
    props.options.isSpaceBetween ? 'is-space-between' : null
  ]
    .filter(Boolean)
    .join(' ')
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/color'

.k-field-kolcenter
  --field-label-flex-grow: 1

  position: relative
  min-height: 36px
  &:not(:last-child)
    margin-bottom: size.$gap * .25
  &.bordered
    border-bottom: 1px solid color.$concrete
  &.is-horizontal
    .field-label
      text-align: left
    .field-body
      flex-grow: 3
    .input
      text-indent: -25%
      &:focus
        box-shadow: none

  .field-label
    flex-grow: var(--field-label-flex-grow)

  .label
    font-size: font.$normal
  .is-grouped
    .control, .field
      flex-grow: 1

  &.required
    .field-label
      display: flex
    .field-label label
      position: relative
      flex-shrink: 0
      &:after
        position: relative
        top: .25rem
        margin-left: .5rem
        content: '*'
        color: color.$grey
        font-size: font.$size-5

  &.skip-label
    .field-label
      display: none

  &.is-space-between
    .field-label
      display: flex
      justify-content: flex-start
      flex-grow: 2
    .field-body .field
      display: flex
      justify-content: flex-end
</style>
