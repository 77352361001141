export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
export const FETCH_PRODUCTS_FOR_SEARCH_SUCCESS =
  'FETCH_PRODUCTS_FOR_SEARCH_SUCCESS'
export const GET_RELATED_PRODUCTS_SUCCESS = 'GET_RELATED_PRODUCTS_SUCCESS'
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS'
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCTS_SUCCESS = 'DELETE_PRODUCTS_SUCCESS'
export const FETCH_PRODUCT_VIDEOS_SUCCESS = 'FETCH_PRODUCT_VIDEOS_SUCCESS'
export const GET_PRODUCT_VIDEO_SUCCESS = 'GET_PRODUCT_VIDEO_SUCCESS'
export const UPLOAD_PRODUCT_VIDEO_SUCCESS = 'UPLOAD_PRODUCT_VIDEO_SUCCESS'
export const DELETE_PRODUCT_VIDEO_SUCCESS = 'DELETE_PRODUCT_VIDEO_SUCCESS'
export const CLEAR_STORE = 'CLEAR_STORE'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
