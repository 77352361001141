import * as types from '../mutation-types'

export const getPartnerProductPreference = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'getPartnerProductPreference')

  return new Promise((resolve, reject) => {
    model
      .getPartnerProductPreference(options)
      .then((response) => {
        dispatch(
          'partnerProductPreferences/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'getPartnerProductPreference')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getPartnerProductPreference,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const savePartnerProductPreference = (
  { dispatch, commit },
  { model, partnerProductPreference }
) => {
  commit(types.API_REQUEST_START, 'savePartnerProductPreference')

  return new Promise((resolve, reject) => {
    model
      .savePartnerProductPreference(partnerProductPreference)
      .then((response) => {
        dispatch(
          'partnerProductPreferences/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'savePartnerProductPreference')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: savePartnerProductPreference,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              partnerProductPreference
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
