export const FETCH_PROMOTER_MEDIA_SERVICES_SUCCESS =
  'FETCH_PROMOTER_MEDIA_SERVICES_SUCCESS'
export const GET_RELATED_PROMOTER_MEDIA_SERVICES_SUCCESS =
  'GET_RELATED_PROMOTER_MEDIA_SERVICES_SUCCESS'
export const GET_PROMOTER_MEDIA_SERVICE_SUCCESS =
  'GET_PROMOTER_MEDIA_SERVICE_SUCCESS'
export const ADD_PROMOTER_MEDIA_SERVICE_SUCCESS =
  'ADD_PROMOTER_MEDIA_SERVICE_SUCCESS'
export const UPDATE_PROMOTER_MEDIA_SERVICE_SUCCESS =
  'UPDATE_PROMOTER_MEDIA_SERVICE_SUCCESS'
export const DELETE_PROMOTER_MEDIA_SERVICE_SUCCESS =
  'DELETE_PROMOTER_MEDIA_SERVICE_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
