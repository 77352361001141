import PromoterShareProductShip from '../../resource_models/promoter_share_product_ship'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map(
    (id) => new PromoterShareProductShip(state.entities[id])
  )
}

export const find = (state) => (id) => {
  return new PromoterShareProductShip(state.entities[id])
}

export const findBy = (state) => (attributes) => {
  return new PromoterShareProductShip(
    Object.keys(state.entities)
      .map((key) => {
        return state.entities[key]
      })
      .find((record) => {
        return Object.keys(attributes).every((key) => {
          return record[key] === attributes[key]
        })
      })
  )
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}

export const filterBy =
  (state) =>
  (condition = {}) => {
    return all(state).find((record) => {
      const keys = Object.keys(condition)

      return keys.every((key) => {
        return record[key] === condition[key]
      })
    })
  }
