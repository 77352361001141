<template lang="pug">
b-collapse.panel.search-section.k-form-container-admin-search(
  :open.sync="isFormOpen"
  :class="containerClass"
)
  .panel-heading(
    v-if="$slots['header']"
    slot="trigger"
  )
    slot(name="header")
    template(v-if="title")
      | {{ title }}
  .panel-block
    slot(name="body")
  .panel-footer
    slot(name="footer")
</template>

<script setup>
import { ref, computed } from 'vue'

const TYPE_MAP = {
  'is-default': 'is-default',
  'is-header-white': 'is-header-white'
}

const props = defineProps({
  title: { type: String },
  options: {
    type: Object,
    default() {
      return {
        type: 'is-default'
      }
    }
  }
  // subtitle: { type: String, requird: false }
})

const containerClass = computed(() => {
  return [TYPE_MAP[props.options.type]].filter(Boolean).join(' ')
})

const isFormOpen = ref(true)
</script>

<style lang="sass">
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/color'

.k-form-container-admin-search
  border-radius: 6px
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02)
  font-size: 1rem

  &.is-header-white
    .panel-heading
      background: color.$white
      border: 1px solid color.$lighter

  .panel-heading
    background-color: var(--heading-color, hsl(0, 0%, 93%))
    border-radius: 6px 6px 0 0
    color: hsl(0, 0%, 21%)
    font-size: 1.25em
    font-weight: 700
    line-height: 1.25
    padding: 0.75em 1em

  .panel-block
    padding: 1rem .75rem
    align-items: center
    display: flex

    .columns
      width: 100%
  .panel-footer
    padding: size.$gap * 0.5
    display: flex
    justify-content: flex-end
    gap: size.$gap * .5

  .collapse-content
    background-color: color.$white
</style>
