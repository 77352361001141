/**
 * Converts a JSON object to FormData.
 *
 * @param {Object} jsonData - The JSON object to be converted.
 * @param {Object} [options={}] - Additional options for the conversion.
 * @param {boolean} [options.isSkipEmptyArrayKey=false] - If `false`, keys for empty arrays will be preserved in the FormData.
 *                                                        If `true`, keys for empty arrays will be omitted.
 *
 * @return {FormData} The resulting FormData object.
 */

export const convertToFormData = (jsonData, options = {}) => {
  const formData = new FormData()
  const isSkipEmptyArrayKey = options.isSkipEmptyArrayKey

  appendObject(formData, jsonData)

  function appendObject(formData, jsonData, prefix) {
    Object.keys(jsonData).forEach((key) => {
      const value = jsonData[key]
      const name = prefix ? `${prefix}[${key}]` : key

      if (value === undefined) return
      if (value === null) {
        formData.append(name, '')
      } else if (
        value instanceof File ||
        value instanceof Blob ||
        value instanceof Date
      ) {
        formData.append(name, value)
      } else if (Array.isArray(value)) {
        appendArray(formData, value, name)
      } else if (typeof value === 'object' && value !== null) {
        appendObject(formData, value, name)
      } else {
        formData.append(name, value)
      }
    })
  }

  function appendArray(formData, arrayValue, prefix) {
    if (!isSkipEmptyArrayKey && arrayValue.length === 0) {
      return formData.append(`${prefix}[]`, '')
    }

    arrayValue.forEach((singleValue, index) => {
      const name = `${prefix}[]`

      formData.append(`${name}[index]`, index) // to separate element in array
      if (typeof singleValue === 'object' && singleValue !== null) {
        appendObject(formData, singleValue, name)
      } else if (typeof singleValue === Array) {
        appendArray(formData, singleValue, name)
      } else {
        formData.append(name, singleValue)
      }
    })
  }

  return formData
}
