export const FETCH_PROMOTER_SHARES_SUCCESS = 'FETCH_PROMOTER_SHARES_SUCCESS'
export const FETCH_PROMOTER_SHARES_FOR_SEARCH_SUCCESS =
  'FETCH_PROMOTER_SHARES_FOR_SEARCH_SUCCESS'
export const GET_RELATED_PROMOTER_SHARES_SUCCESS =
  'GET_RELATED_PROMOTER_SHARES_SUCCESS'
export const GET_PROMOTER_SHARE_SUCCESS = 'GET_PROMOTER_SHARE_SUCCESS'
export const ADD_PROMOTER_SHARE_SUCCESS = 'ADD_PROMOTER_SHARE_SUCCESS'
export const UPDATE_PROMOTER_SHARE_SUCCESS = 'UPDATE_PROMOTER_SHARE_SUCCESS'
export const FETCH_DESCENDANT_PROMOTER_SHARES_SUCCESS =
  'FETCH_DESCENDANT_PROMOTER_SHARES_SUCCESS'
export const UPLOAD_ATTACHMENTS_SUCCESS = 'UPLOAD_ATTACHMENTS_SUCCESS'

export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS'
export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS'

export const SET_CURRENT_KOL_SHARE_ID = 'SET_CURRENT_KOL_SHARE_ID'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
