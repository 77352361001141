import ResourceModelBase from 'odd-resource_model'
import Tiktok from '../../kolcenter/images/tiktok.svg'
import Line from '../../kolcenter/images/line-icon-r.svg'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_media_services',
  attributes: [
    'id',
    'name',
    'is_enabled',
    'accounts_count',
    'base_url',
    'created_at',
    'updated_at'
  ],
  editableAttributes: ['name', 'is_enabled']
}

export default class PromoterMediaService extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  icon() {
    switch (this.name) {
      case 'Instagram':
        return 'fa fa-instagram'
      case 'Facebook':
        return 'fa fa-facebook'
      case 'Youtube':
        return 'fa fa-youtube'
      case 'Twitter':
        return 'fa fa-twitter'
    }
  }

  imageUrl() {
    switch (this.name) {
      case 'Tiktok':
        return Tiktok
      case 'Line':
        return Line
    }
  }

  // extra methods or helpers here...
}
